
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { currentStore, pdfStore } from '@/store'
import PDFPreview from '@/components/pdfs/PDFPreview.vue'
import PDFFrontpage from '@/components/pdfs/sections/general/PDFFrontpage.vue'
import PDFParadigmIntro from '@/components/pdfs/sections/mapping/PDFParadigmIntro.vue'
import PDFParadigmInspection from '@/components/pdfs/sections/mapping/PDFParadigmInspection.vue'
import PDFParadigmValues from '@/components/pdfs/sections/general/PDFParadigmValues.vue'
import PDFParadigmDGNB from '@/components/pdfs/sections/general/PDFParadigmDGNB.vue'
import PDFTableOfContents from '@/components/pdfs/sections/general/PDFTableOfContents.vue'
import PDFComments from '@/components/pdfs/sections/general/PDFComments.vue'
import PDFSamples from '@/components/pdfs/sections/general/PDFSamples.vue'
import PDFCategories from '@/components/pdfs/sections/general/PDFCategories.vue'
import PDFParadigmHandling from '@/components/pdfs/sections/general/PDFParadigmHandling.vue'
import PDFParadigmRemediation from '@/components/pdfs/sections/general/PDFParadigmRemediation.vue'

export default defineComponent({
  name: 'FullReport',
  props: {
    reference: {
      type: String,
      default: '',
    },
    initFetch: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(pdfStore, ['configs']),
    ...mapState(currentStore, {
      reportNotes: (store) => (store.project?.data.reportNotes ? 1 : 0),
    }),
    key() {
      return this.reference || this.$route.query.reference
    },
    pdf() {
      const reference =
        this.reference || (this.$route.query.reference as string)

      return reference
        ? this.configs.reports[reference]
        : this.configs.reports.fullReport
    },
    tocElementsSelector() {
      return `[data-report-reference="${this.pdf.reference}"] .PDFPage:not(.NoTOC)`
    },
    initiatePDFRequest() {
      // 1. Ensure fetch is requested && pdf have been loaded in DOM
      return this.initFetch
    },
  },
  watch: {
    initFetch: {
      handler(bool) {
        // Mimic the initial process from PDFPreview when the DOM is building
        if (bool) {
          this.statusChanged('process:creating-pdf')
        }
      },
      immediate: true,
    },
  },
  methods: {
    statusChanged(str: string) {
      this.$emit('status-changed', {
        reportType: this.pdf.reference,
        status: str,
      })
    },
    getContentIndex(number: number) {
      return number - (this.reportNotes ? 0 : 1)
    },
  },
  components: {
    PDFPreview,
    PDFFrontpage,
    PDFTableOfContents,
    PDFParadigmIntro,
    PDFParadigmInspection,
    PDFParadigmValues,
    PDFParadigmDGNB,
    PDFComments,
    PDFSamples,
    PDFCategories,
    PDFParadigmHandling,
    PDFParadigmRemediation,
  },
})
