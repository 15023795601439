
import PDFPage from '@/components/pdfs/PDFPage.vue'
import { currentStore } from '@/store'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PDFParadigmInspection',
  props: {
    section: {
      type: Number,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(currentStore, {
      paradigmContent: 'paradigmContent',
      samplesCount: ({ samples }) => samples.standard.length,
    }),
    isBuildingContext() {
      return !!this.context.split('-')[1]
    },
  },
  methods: {
    link(name: string) {
      const { protocol, host } = window.location
      return `${protocol}//${host}${this.$router.resolve({ name }).href}`
    },
  },
  components: { PDFPage },
})
