
import PDFPage from '@/components/pdfs/PDFPage.vue'
import { currentStore } from '@/store'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PDFParadigmIntro',
  props: {
    section: {
      type: Number,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(currentStore, ['paradigmContent']),
  },
  components: { PDFPage },
})
