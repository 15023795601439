
import PDFPage from '@/components/pdfs/PDFPage.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PDFParadigmDGNB',
  props: {
    section: {
      type: Number,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
  },
  components: { PDFPage },
})
